.page {
  padding-top: 105px;
  padding-bottom: 122px;
  overflow: hidden;

  p {
    color: #4b567a;
    font-size: 22px;
    font-family: san_francisco;
    font-weight: 400;
    line-height: 36px;
  }
  ol {
    padding-left: 30px;
    li {
      padding: 80px 0 58px 0;
      color: #4b567a;
      font-size: 32px;
      font-family: san_francisco;
      font-weight: 600;
      line-height: 34px;
    }
  }
}


