.not-found-page{
  .block-wrap{
    text-align: center;
  }
  .description{
    position: relative;
    top: -145px;
    color: #4b567a;
    .title{
      font-family: Montserrat, sans-serif;
      font-size: 44px;
      font-weight: 600;
    }
    .subtitle{
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .ios-button{
    padding: 11px 102px 12px;
    margin: 0 auto;
  }
}