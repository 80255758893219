@mixin respond-to($media) {
  @if $media == small-screens {
    @media (max-width: $sm) { @content; }
  }
  @else if $media == medium-screens {
    @media (max-width: $md) { @content; }
  }
  @else if $media == large-screens {
    @media (max-width: $lg) { @content; }
  }
  @else if $media == huge-screens {
    @media (max-width: $xl) { @content; }
  }
  @else if $media == ex-huge-screens {
    @media (min-width: $sl) { @content; }
  }
}