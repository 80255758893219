/*!
Theme Name: ScreenTime
Author: ekreative
Author URI: https://www.ekreative.com/
Description: ScreenTimeChallenge theme
Version: 1.0.0
Text Domain:  screentime-child
Tags:

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
*/
@import "normalize";
@import "variables";
@import "mixins";
@import "parts/header";
@import "parts/home";
@import "parts/footer";
@import "parts/404";
@import "fonts";
@import "parts/page/page-header";
@import "parts/page/body-page";
@import "parts/page/footer";