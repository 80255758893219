.page-section {
  background: url("../images/bg-page.png") no-repeat;
  background-size: cover;
  .header-wrapper {
    padding: 46px 0 56px;
    text-align: center;
    margin: 0 auto;
  }

  .brand-logo-wrapper {
    display: block;
    margin: 0 auto;
  }
  .page-title {
    padding-top: 46px;
    color: #ffffff;
    margin: 0 auto;
    font-size: 60px;
    line-height: 54px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
  .page-caption {
    margin: 0 auto;
    width: 113px;
    padding: 36px 0 46px;
    color: #ffffff;
    font-size: 18px;
    font-family: Montserrat;
    line-height: 23px;
  }
}