/* Fonts */
//$font-italic: 'hk_groteskitalic', Arial, sans-serif;
//$font-bold: 'hk_groteskbold', Arial, sans-serif;
//$font-semibold: 'hk_grotesksemibold', Arial, sans-serif;
//$font-semibold-italic: 'hk_grotesksemibold_italic', Arial, sans-serif;
//$font-bold-italic: 'hk_groteskbold_italic', Arial, sans-serif;
//$font-medium: 'hk_groteskmedium', Arial, sans-serif;
//$font-medium-italic: 'hk_groteskmedium_italic', Arial, sans-serif;
//$font-light: 'hk_grotesklight', Arial, sans-serif;
//$font-light-italic: 'hk_grotesklight_italic', Arial, sans-serif;


/* Font sizes */
$fs-biggest: 72px;
$fs-large: 48px;
$fs-bigger: 36px;
$fs-big: 24px;
$fs-main: 22px;
$fs-low: 20px;
$fs-medium: 18px;
$fs-small: 16px;

/* Colours */


/* Breakpoints */
$iphone5: 360px;
$xs: 576px;
$sm: 767px;
$md: 991px;
$lg: 1023px;
$xl: 1440px;
$sl: 1599px;