.main-wrapper {
  overflow: hidden;
}

.feature, .about-us {
  padding-top: 3.92rem;
  @media (max-width: 980px) {
    padding-top: 1.5rem;
  }

}

.img-section {
  padding-bottom: 229px;
  @media (min-width: 1200px){
    margin-top: -74px;
  }
  @media (max-width: 1199px) {
    margin-top: -170px;
  }
  @include respond-to(small-screens) {
    padding-bottom: 55px;
  }

  .section-wrap {
    margin: 270px 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 980px){
      flex-direction: column;
    }
  }

  .img-block {
    @media (max-width: 980px) {
      //padding-bottom: 98px;
      width: 100%;
    }
    p {
      width: 300px;
      color: #4b567a;
      font-size: 22px;
      font-weight: 500;
      line-height: 40px;
      @include respond-to(small-screens) {
        font-size: 18px;
        line-height: 32px;
        width: unset;
        padding-bottom: 102px;
        padding-top: 45px;
      }
      @media (min-width: 500px) and (max-width: 980px){
        width: 400px;
        margin: 0 auto;
        padding-bottom: 102px;
        padding-top: 45px;
      }


    }
  }
}

.feature {
  margin: 0 auto;
  @media (max-width: 980px) {
    .ios-button {
      margin: 0 auto;
    }
  }
}

.description-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width:980px){
    flex-direction: column;
  }

  .img-block {
    width: 50%;
  }

  .img-wrap {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .feature {
    margin: 0 auto;
  }

  .description-wrap {
    display: flex;
    justify-content: space-between;
  }

  .description-image {
    width: 47%;
    @media (max-width: 980px) {
      width: unset;
      img {
        max-width: 100%;
      }
    }
  }

  .content-wrap {
    text-align: left;
    width: 45%;
    @media (max-width: 980px){
      margin: 0 auto;
      width: unset;
      padding-top: 70px;
      text-align: center;
    }
  }

  .feature-title {
    color: #4b567a;
    font-family: Montserrat;
    font-size: 44px;
    font-weight: 600;
    line-height: 54px;
    @include respond-to(small-screens) {
      font-size: 36px;
    }
    @media (max-width: 405px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .caption {
    color: #4b567a;
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
    padding-bottom: 50px;
    @media (max-width: 767px) {
      font-size: 19px;
      line-height: 32px;
    }
    @media (max-width: 405px) {
      font-size: 18px;
    }
  }
}

.control-screen {
  background-position: 87vw;
  padding-bottom: 130px;
  background-image: url('../images/bg-section.png');
  background-repeat: no-repeat;
  @media (min-width: 1200px) {
    margin-top: -70px;
    padding-top: 0;
  }
  @media (max-width: 980px) {
    margin-top: -85px;
    padding-top: 0;
    padding-bottom: 0;
    background-image: none;
  }
}

.time-limits {
  padding-bottom: 192px;
  background-position: -28vw;
  background-image: url('../images/bg_section-2.png');
  background-repeat: no-repeat;
  @media (min-width: 1200px) {
  padding-top: 3.92rem;
  }
  @media (max-width: 980px) {
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: none;
  }



  .description-wrap {
    flex-direction: row-reverse;
    @media (max-width: 980px){
      flex-direction: column;
    }
  }
}


.about-us{
  position: relative;
  @include respond-to(small-screens) {
    padding-top: 0;
  }
  background-color: #f1f9ff;

  .container {
    padding-bottom: 235px;
    width: 100%;
    @include respond-to(small-screens) {
      padding: 0 15px 450px;
    }
  }

  .media-list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .media-layer {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .media-element {
    pointer-events: all;
    position: absolute;
    z-index: 10;
    display: block;
  }

  .media-logo {
    width: 100%;
  }

  .media-shadow {
    border-radius: 50%;
    box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.03);
  }

  @keyframes vertical {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(0, 50px, 0);
    }
  }
  @keyframes horizontal {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(50px, 0, 0);
    }
  }
  @keyframes diagonal-right {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(50px, 50px, 0);
    }
  }
  @keyframes diagonal-left {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-50px, 50px, 0);
    }
  }

  .media-techcrunch {
    left: 1%;
    top: -90px;
    width: 175px;
    animation: vertical 5s linear 0.5s infinite alternate;
  }

  .media-business-insider {
    right: 36%;
    top: -50px;
    width: 170px;
    animation: horizontal 4s linear 1s infinite alternate;
  }

  .media-sky-news {
    right: 3%;
    top: -75px;
    width: 175px;
    animation: diagonal-right 5s linear 0s infinite alternate;
  }

  .media-gizmodo {
    top: 290px;
    left: 13%;
    width: 135px;
    animation: diagonal-left 4s linear 1s infinite alternate;
  }

  .media-independent {
    left: 28%;
    top: 35px;
    width: 205px;
    animation: horizontal 5s linear 0.5s infinite alternate;
  }

  .media-blue {
    top: 150px;
    left: -256px;
    width: 145px;
    height: 145px;
    border-radius: 50%;
    background-color: #8092e8;
    animation: vertical 4s linear 0.5s infinite alternate;
  }

  .media-darkblue {
    right: 29%;
    top: 150px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    background-color: #4b567a;
    animation: diagonal-right 5s linear 1s infinite alternate;
  }

  .media-wired {
    top: 35px;
    right: 16%;
    width: 140px;
    animation: diagonal-left 4s linear 0.5s infinite alternate;
  }

  .media-red {
    top: 65px;
    left: 16%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fd544f;
    animation: horizontal 5s linear 0s infinite alternate;
  }

  .media-times {
    right: 1%;
    top: 150px;
    width: 205px;
    animation: vertical 4s linear 1s infinite alternate;
  }

  .media-bbc {
    right: 20%;
    top: 450px;
    width: 170px;
    animation: diagonal-left 5s linear 0.5s infinite alternate;
  }

  .media-cnn {
    left: 3%;
    top: 565px;
    width: 205px;
    animation: diagonal-right 4s linear 0s infinite alternate;
  }

  .media-verge {
    top: 450px;
    left: 23%;
    width: 175px;
    animation: horizontal 5s linear 1s infinite alternate;
  }

  .media-ny-times {
    right: 6%;
    top: 600px;
    width: 135px;
    animation: vertical 4s linear 0.5s infinite alternate;
  }

  .media-mirror {
    right: 14%;
    top: 320px;
    width: 110px;
    animation: diagonal-left 5s linear 0s infinite alternate;
  }

  @media (max-width: 1199px) {
    .media-techcrunch {
      top: -45px;
      left: 8%;
    }
    .media-independent {
      left: 1%;
      top: 220px;
    }
    .media-red {
      top: -15px;
      left: 55%;
    }
    .media-darkblue {
      bottom: 60px;
      right: 40%;
      top: 550px;
      left: 0;
    }
    .media-blue {
      top: auto;
      left: auto;
      bottom: 60px;
      right: 40%;
    }
    .media-gizmodo {
      top: auto;
      bottom: 200px;
      left: 16%;
    }
    .media-cnn {
      top: auto;
      bottom: -25px;
      left: 24%;
    }
    .media-sky-news {
      right: 24%;
      top: 130px;
    }
    .media-wired {
      left: 30%;
      top: 150px;
    }
    .media-mirror {
      top: auto;
      bottom: 185px;
      right: 27%;
    }
    .media-business-insider {
      right: 2%;
      top: 265px;
    }
    .media-bbc {
      right: auto;
      left: 2%;
      top: auto;
      bottom: -20px;
    }
    .media-verge {
      left: auto;
      right: 8%;
      top: auto;
      bottom: 20px;
    }
    .media-ny-times {
      right: 6%;
      top: 35px;
      bottom: auto;
    }
    .media-times {
      right: 5%;
      top: auto;
      bottom: 250px;
    }
  }

  @media (max-width: 767px) {
    .media-techcrunch {
      width: 105px;
      height: 105px;
      left: 0;
      top: -30px;
    }
    .media-darkblue {
      width: 60px;
      height: 60px;
      left: 53%;
      top: -10px;
    }
    .media-wired {
      width: 85px;
      height: 85px;
      top: 80px;
      left: 38%;
    }
    .media-independent {
      width: 120px;
      height: 120px;
      left: 13%;
      top: 120px;
    }
    .media-sky-news {
      width: 105px;
      height: 105px;
      right: 20%;
      top: 40px;
    }
    .media-business-insider {
      width: 100px;
      height: 100px;
      right: 0;
      top: 190px;
    }
    .media-blue {
      width: 85px;
      height: 85px;
      top: 285px;
      left: -20px;
    }
    .media-bbc {
      width: 100px;
      height: 100px;
      left: 0;
      bottom: 320px;
    }
    .media-times {
      width: 105px;
      height: 105px;
      right: 2%;
      bottom: 315px;
    }
    .media-verge {
      width: 105px;
      height: 105px;
      bottom: 40px;
      right: 5%;
    }
    .media-mirror {
      width: 65px;
      height: 65px;
      right: 7%;
      bottom: 190px;
    }
    .media-red {
      width: 60px;
      height: 60px;
      bottom: 150px;
      right: 29%;
      left: auto;
      top: auto;
    }
    .media-ny-times {
      width: 80px;
      height: 80px;
      right: 36%;
      bottom: 255px;
      top: auto;
    }
    .media-gizmodo {
      width: 100px;
      height: 100px;
      bottom: 185px;
      left: 20%;
    }
    .media-cnn {
      width: 120px;
      height: 120px;
      left: 30%;
      bottom: 80px;
    }
  }
  @media (max-width: 539px) {
    .media-techcrunch {
      left: -10px;
      top: -30px;;
    }
    .media-darkblue {
      left: 48%;
      top: -10px
    }
    .media-wired {
      top: 70px;
      left: 32%;
    }
    .media-independent {
      left: 3%;
      top: 120px;
    }
    .media-sky-news {
      right: 6%;
      top: 40px;
    }
    .media-blue {
      top: 285px;
      left: -30px
    }
    .media-bbc {
      left: -10px;
      bottom: 320px;
    }
    .media-gizmodo {
      bottom: 185px;
      left: 5%;
    }
    .media-cnn {
      left: 10%;
      bottom: 50px;
    }
  }
}

.about-us-wrap {
  position: relative;
  width: 300px;
  margin: 0 auto;
  padding-top: 297px;
  text-align: center;
  @include respond-to(small-screens) {
    padding-top: 290px;
  }
  @media (max-width: 379px) {
    width: 100%;
  }

  .title {
    font-family: Montserrat, sans-serif;
    color: #4b567a;
    font-size: 60px;
    line-height: 70px;
    width: 300px;
    @include respond-to(small-screens) {
      margin: 0 0 30px;
    }
    @media (max-width: 539px) {
      font-size: 50px;
      line-height: 60px;
    }
    @media (max-width: 380px) {
      margin: 0 auto;
    }
  }

  .caption {
    font-size: 24px;
    color: #4b567a;
    line-height: 40px;
    @media (max-width: 539px) {
      font-size: 18px;
      line-height: 32px;
    }
  }
}
