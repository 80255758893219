*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-family: san_francisco !important;
  line-height: 32px;
  font-size: 16px;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* Welcome section styles */

.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
  &.visible {
    display: block;
  }
}
.welcome-section {
  background: url("../images/bg.png") no-repeat right;
  background-size: 100% 100%;
  min-height: 935px;
  @media (min-width: 1000px)and (max-width: 1199px){
    .container{
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    .container{
      max-width: 1270px;
      padding-right: 0;
      margin-right: 0;
    }
  }
  @media (max-width: 1199px){
    background: url("../images/bg.png") no-repeat center;
    background-size: cover;
  }
  @include respond-to(small-screens){
    background: url("../images/bg-mobile.png") no-repeat center/cover;
    min-height: auto;
    height: auto;
    background-position: bottom;
  }
}
.container{
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;

}
.container-welcome{
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  padding: 30px 0;
  padding-top: 160px;
 @media (max-width: 1199px) {
   padding-top: 104px;
   flex-direction: column;
 }
  @media (max-width: 400px) {
    padding-top: 60px;
  }
}



.brand-logo-wrapper{
  display: flex;
  flex-direction: row;
  padding: 26px 0 0;
  max-width: 1300px;
  //margin: 0 auto;
  justify-content: space-between;
  @include respond-to(small-screens){
    padding: 15px 0;
  }
  .phone-support{
    margin: 0;
    h3, a, p{
      color: #ffffff;
      margin: 0;
      line-height: 36px;
    }
    h3{
      font-size: 18px;
      line-height: 36px;
      font-weight: 400;
    }
    a{
      font-size: 27px;
      line-height: 32px;
      font-weight: 600;
    }
    p{
      font-size: 13px;
      line-height: 32px;
    }

    @media (max-width: 1399px) {
      padding-right: 30px;
    }
    @include respond-to(small-screens){
        visibility: hidden;
      display: none;
    }
  }
}
.head-text-wrap {
  display: flex;
  flex-direction: column;
  .ios-button {
    @media (max-width: 1199px) {
      text-align: unset;
      margin: 0 auto;
    }
  }

  h2 {
    color: #ffffff;
    font-size: 40px;
    font-family: "Montserrat";
    font-weight: 600;
    line-height: 60px;
    @media (min-width: 1200px) {
      width: 462px;
    }
  }
  @media (min-width: 768px) and (max-width:1199px) {
    width: 600px;
    padding-bottom: 70px;
    text-align: center;
    margin: 0 auto;
  }
  @include respond-to(small-screens){
    h2{
      text-align: center;
      font-size: 30px;
      line-height: 43px;
    }
    .head-text-description{
      font-size: 19px;
      line-height: 32px;
    }
    font-size: 18px;
    line-height: 32px;
    text-align: center;
  }
  @media (max-width: 405px) {
    h2{
        font-size: 22px;
        line-height: 30px;
      }
    .head-text-description{
      font-size: 18px;
    }
    }
  }

.head-text-description{
  color: #ffffff;
  font-family: san_francisco;
  font-size: 22px;
  font-weight: 400;
  line-height: 34px;
  padding-bottom: 70px;
  @media (min-width: 1200px) {
    width: 466px;
  }

}
.ios-button {
  display: flex;
  padding: 8px 75px 14px;
  color: #ffffff;
  border-radius: 35px;
  font-size: 24px;
  background-color: #64cc8b;
  -webkit-box-shadow: none;
  cursor: pointer;
  max-width: 300px;
  box-shadow: 0px 30px 40px 0px rgba(85,92,193,0.3);
  font-weight: bold;
  @media (min-width: 869px) {
    text-align: left;
  }
  @media (max-width: 768px) {
    margin: 0 auto;
  }
  @media (max-width: 350px) {
    padding: 8px 57px 14px;
  }
  .fa{
    font-size: 39px;
    display: inline;
    padding: 5px 7px 0 0;
  }
}
.ios-btn-wrap{
  display: flex;
  flex-direction: column;
}
.ios-text-top{
  font-size: 13px;
  font-weight: 500;
}
.ios-text-bottom{
  line-height: 0;
}
.video-wrap{
  @media (min-width: 1400px) {
    margin-right: -12%;
  }
@media (min-width: 1200px) and (max-width: 1399px){
  margin-right: -9%;
}
@media (max-width: 768px) {
  padding-top: 54px;
  }
}
.video-preview {
  position: relative;
  z-index: 9999;
  display: flex;
  flex-direction: row-reverse;
  width: 650px;
}
.play-video-button {
  width: 100%;
  //height: 100%;
  background-size: 100% auto;
  position: relative;
  z-index: 2;
  @media (min-width: 1200px ) {
    height: 100%;
  }
  @media (min-width: 768px) and (max-width: 980px){
    img{
      width: 94%;
    }
  }
  @include respond-to(large-screens) {
    align-self: center;
  }
  @include respond-to(small-screens) {
    margin: 0 auto;
    padding-bottom: 90px;
  }
  @include respond-to(small-screens) {
    padding-bottom: 68.7%;
  }
  &.play {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    background-size: 100% 100%;
    border-radius: 10px;
    @include respond-to(medium-screens) {
      width: 90%;
      height: 162px;
    }
  }
  &.shadow {
    -webkit-box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.4);
  }
  #ytplayer {
    width: 100%;
    height: 100%;
  }
}
.video-preview {
  position: relative;
  right: -80px;
  z-index: 9999;
  width: 650px;
  height: 448px;
  box-shadow: 0px 30px 40px 0px rgba(85,92,193,0.3);
  border-radius: 18px;
  overflow: hidden;
  transition: all 0.2s ease-in;
  background: url("../images/video-preview.png") center center / contain no-repeat;
  @media (min-width: 1200px) and (max-width: 1399px){
    width: 600px;
    height: 412px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    width: 600px;
    height: 412px;
  }
  @media (max-width: 1199px) {
    margin: 0 auto;
    right: auto;
    width: 650px;
    height: 448px;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
  @media (max-width: $xs) {
    border-radius: 5px;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
  &.shadow {
    .play-video-button {
      //position: absolute;
      z-index: -1;
    }
    .yt-wrap {
      position: absolute;
      opacity: 1;
    }
  }
  &.play {
    .yt-wrap {
      width: 100%;
      padding-bottom: 64.25%;
      padding-top: 30px; height: 0; overflow: hidden;
      @media (max-width: $xs) {
        padding-bottom: 62.25%;
      }
      @media (max-width: $iphone5) {
        padding-bottom: 57.25%;
      }
      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.yt-wrap {
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: opacity 300ms ease-in;
  padding-bottom: 56.25%;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.8);
  @media (max-width: 1199px) {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8)
  }
  @media (max-width: $xs) {
    border-radius: 5px;
  }
}
