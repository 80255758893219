/* Footer */
footer {
  background: url("../images/bg_footer.png") no-repeat ;
  background-size: cover;
  background-position: center;
  background-color: #f1f9ff;
  @include respond-to(small-screens){
    background: url("../images/bg_mobile_footer.png") no-repeat ;
    background-size: cover;
    background-position: center;
    background-color: #f1f9ff;
  }

  h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    padding-bottom: 3px;
  }
  a {
    color: #ffffff;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.28px;
    display: flex;
    align-items: center;
    @media (min-width: 992px) and (max-width: 1100px){
      font-size: 25px;
    }
    @media (max-width: 375px) {
      font-size: 22px;
    }
  }
  p {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.17px;
    padding-top: 6px;
    color: #F0F0EE;
  }
  ul {
    list-style: none;
    li {
      display: inline-block;
      padding-right: 6px;
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}

.download-free, .support-team, .phone-support {
  @include respond-to(medium-screens) {
    display: block;
  }
}
.support-team {
  @media (min-width: 320px) and (max-width: 470px) {
    a {
      font-size: 22px;
    }
  }
  @include respond-to(medium-screens) {
    margin-top: 21px;
  }
}
.download-free {
  padding-bottom: 40px;

  @media (min-width: 774px) and (max-width: 990px) {
    position: relative;
    left: 16%;
  }
  @include respond-to(medium-screens) {
    width: 100%;
    text-align: center;
    padding-top: 36px;
    h3 {
      display: none;
    }
  }
  .ios-button{
    background-color: #ffffff;
    .fa, .ios-btn-wrap{
      color: #5d66d6;
    }
  }

}
.footer-container, .copyring {
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include respond-to(medium-screens) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.footer-container {
  max-width: 1100px;

  padding-bottom: 28px;
  @media (min-width: 1000px) {
    padding-top: 48px;
  }
  @media (max-width: 999px){
    text-align: center;
    padding-bottom: 0;
    max-width: 100%;
  }
}
.footer-wrap {
  padding-top: 160px;
  padding-right: 32px;
  padding-left: 32px;
  @include respond-to(medium-screens) {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
  }
}
.copyring {
  max-width: 1120px;
  border-top: 1px solid #f9f9f9;
  padding-top: 10px;
  padding-bottom: 35px;
  p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }
  @include respond-to(medium-screens) {
    display: block;
    text-align: center;
    margin: 0 auto;
    width: 90%;
  }
}
.download-free {
  @media (min-width: 992px) {
    top: 65px;
    position: relative;
  }
  .ios-button{
    padding: 1px 75px 15px;
    .fa{
      padding: 15px 7px 0 0;
    }
  }
}

.phone-support {
  @media (max-width: 375px) {
    a {
      position: relative;
      left: 14%;
    }

  }
}

@media (max-width: 999px) {
  .phone-support, .download-free{
    margin: 0 auto;
  }
}





