$fa-css-prefix: 'fa';
$fa-font-path: "../fonts/fa";
@import 'font-awesome';

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');

@font-face {
  font-family: 'san_francisco';
  src: url('../fonts/SFUIDisplay-Regular.otf') format('opentype'),
  url('../fonts/SFUIDisplay-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'san_francisco';
  src: url('../fonts/SFUIDisplay-Medium.otf') format('opentype'),
  url('../fonts/SFUIDisplay-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'san_francisco';
  src: url('../fonts/SFUIDisplay-Semibold.otf') format('opentype'),
  url('../fonts/SFUIDisplay-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}